import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../../Layouts/Layout'
import ReadingContainer from '../../components/ReadingContainer/ReadingContainer'
import { PrimaryButton } from '../../components/Button/Button'
import ContentEditableDiv from '../../components/ContentEditableDiv/ContentEditableDiv'
import Input from '../../components/Input/Input'
import InputContainer from '../../components/InputContainer/InputContainer'

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  updateContenteditable = ({ msg: message }) => {
    this.setState({ message })
  }

  sendFetch = () => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...this.state,
      }),
    })
      .then(() => navigate(`/contact/thanks/`))
      .catch(error => alert(error))
  }

  handleSubmit = e => {
    e.preventDefault()
    this.sendFetch()
  }

  render() {
    return (
      <Layout>
        <ReadingContainer>
          <h1>Contact</h1>
          <p>
            Please contact me if you would like to know more about my work or
            would like to work with me in the future.
          </p>
          <form
            // name="contact"
            method="post"
            // action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div>
              <label htmlFor="name">Your name</label>
              <div>
                <Input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  id="name"
                  required
                  placeholder="Enter your name"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <div>
                <Input
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  id="email"
                  required
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <InputContainer>
                <ContentEditableDiv
                  name="message"
                  submissionAction={this.sendFetch}
                  updateAction={this.updateContenteditable}
                  required
                />
              </InputContainer>
            </div>
            <div className="field">
              <PrimaryButton type="submit">Send</PrimaryButton>
            </div>
          </form>
        </ReadingContainer>
      </Layout>
    )
  }
}
