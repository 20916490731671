import styled from '@emotion/styled'
import type { Theme } from '../../types'

const ReadingContainer = styled.div<{ theme: Theme }>`
  max-width: ${({ theme }) => theme.spacing.paragraphWidth};
  padding: 1em;
  margin: auto;
`

export default ReadingContainer
