import styled from '@emotion/styled'

const InputContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.muted};
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
  margin: 16px 0;
  color: #2a2a2a;
  padding: 0 6px;

  :focus-within {
    box-shadow: ${({ theme }) => theme.boxShadow},
      0 0 0 5px ${({ theme }) => theme.color.background},
      0 0 0 9px ${({ theme }) => theme.color.tertiary};
  }
`
export default InputContainer
