import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const RelativeContainer = styled.div`
  position: relative;
`
const ContentEditable = styled.div`
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 2px ${({ theme }) => theme.inputTextIndent};
  font-weight: 400;
  max-height: 210px;
  min-height: 1em;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 2px;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  user-select: text;
`

const Placeholder = styled.div`
  color: ${({ theme }) => theme.color.muted};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.inputTextIndent};
`

const onKeyDown = e => {
  // stop putting a new line in
  if (+e.which === 13 && !e.shiftKey) {
    e.preventDefault()
  }
}

// Example onKeyUp
const onKeyUp = (e, submitAction, otherwise = () => {}) => {
  let msg = e.target.innerHTML.trim()
  const payload = { msg, userType: 'user' }

  if (+e.which === 13 && !e.shiftKey) {
    e.preventDefault()
    if (msg !== '') {
      submitAction(payload)
    }
    e.target.innerHTML = ''
    msg = ''
  }
  otherwise(payload)
}

const ContentEditableDiv = ({
  placeholderMsg,
  placeholder,
  submissionAction,
  updateAction,
  ...props
}) => {
  const [showPlaceholder, togglePlaceholder] = useState(true)

  return (
    <RelativeContainer>
      <ContentEditable
        contentEditable
        spellCheck
        onKeyDown={onKeyDown}
        onKeyUp={e => onKeyUp(e, submissionAction, updateAction)}
        onFocus={() => togglePlaceholder(false)}
        onBlur={e => {
          if (!e.target.innerHTML) {
            togglePlaceholder(true)
          }
        }}
        {...props}
      />
      {showPlaceholder && (
        <>
          {placeholder || (
            <Placeholder>{placeholderMsg || `Type a message`}</Placeholder>
          )}
        </>
      )}
    </RelativeContainer>
  )
}

ContentEditableDiv.propTypes = {
  placeholderMsg: PropTypes.string,
  placeholder: PropTypes.node,
  updateAction: PropTypes.func,
  submissionAction: PropTypes.func.isRequired,
}

ContentEditableDiv.defaultProps = {
  placeholderMsg: null,
  placeholder: null,
  updateAction: () => {},
}

export default ContentEditableDiv
