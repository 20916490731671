import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import InputContainer from '../InputContainer/InputContainer'

const ActiveInput = styled.input`
  width: 100%;
  :focus {
    outline: none;
  }
`
const Input = ({ className, ...otherProps }) => {
  return (
    <InputContainer className={className}>
      <ActiveInput {...otherProps} />
    </InputContainer>
  )
}

Input.propTypes = {
  className: PropTypes.string,
}
Input.defaultProps = {
  className: null,
}
export default Input
